import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './pages/signin/signin.component';
import { SignoutComponent } from './pages/signout/signout.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { WebformComponent } from './pages/webform/webform.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { ElearningComponent } from './pages/elearning/elearning.component';

const routes: Routes = [

  { path: "signin", component: SigninComponent },
  { path: "signout", component: SignoutComponent },
  { path: "unauthorized", component: UnauthorizedComponent },
  { path: "webform", component: WebformComponent }, //TODO: (2024-08-28) Ta bort denna när det inte längre finns länkar med "/webform" hit...
  
  { path: "", component: OverviewComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  
  { path: "elearning", component: ElearningComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  { path: "elearning/:videoId", component: ElearningComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  
  { path: "admin", loadChildren: () => import("./modules/admin/admin.module").then(m => m.AdminModule), canActivate: [AutoLoginPartialRoutesGuard] }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
