
<div class="bg-skyblue-dark py-3 py-xl-4 mb-3">
    <h3 class="text-center mb-0">Välkommen till SIR:s indataportal</h3>
</div>

<div class="container">
    <service-error [messageArr]="serviceError()"></service-error>
</div>

<ng-container *ngIf="loading() == false">

<div class="container">

    <!-- NOTISER -->

    <ng-container *ngIf="isAdmin()">
        <div class="card">
            <div class="card-header border-bottom-0 clickable" (click)="toggleOpenSIRnotis()">
                <div class="row">
                    <div class="col-auto"> <i class="bi bi-pin-fill" style="font-size:1.3rem"></i> </div>
                    <div class="col align-self-center"> <h6>Skapa notis för SIR</h6> </div>
                    <div class="col-auto">
                        <i class="bi float-end" [class.bi-chevron-down]="!openSIRnotis()"
                                                [class.bi-chevron-up]="openSIRnotis()" style="font-size:1.3rem"></i>
                    </div>
                </div>
            </div>
            <div *ngIf="openSIRnotis()" class="card-body pt-0">
                <ng-container [ngTemplateOutlet]="notisBody" [ngTemplateOutletContext]="{id: 2}"></ng-container>
            </div>
        </div>
        <div class="card">
            <div class="card-header border-bottom-0 clickable" (click)="toggleOpenOTIMOnotis()">
                <div class="row">
                    <div class="col-auto"> <i class="bi bi-lightning-fill" style="font-size:1.3rem"></i> </div>
                    <div class="col align-self-center"> <h6>Skapa notis för OTIMO</h6> </div>
                    <div class="col-auto">
                        <i class="bi float-end" [class.bi-chevron-down]="!openOTIMOnotis()"
                                                [class.bi-chevron-up]="openOTIMOnotis()" style="font-size:1.3rem"></i>
                    </div>
                </div>
            </div>
            <div *ngIf="openOTIMOnotis()" class="card-body pt-0">
                <ng-container [ngTemplateOutlet]="notisBody" [ngTemplateOutletContext]="{id: 1}"></ng-container>
            </div>
        </div>
        <ng-template #notisBody let-id="id">
            <form [formGroup]="notisForm()" (ngSubmit)="saveNotis(id)">
                <div class="row">
                    <div class="col-12">
                        <label for="text" class="col-form-label">Meddelande</label>
                        <input id="text" class="form-control" formControlName="text" placeholder="Skriv ditt meddelande" />
                        <p *ngIf="formText()?.touched && formText()?.invalid" class="form-text invalid-feedback">Skriv ett meddelande</p>
                    </div>
                    <div class="col-auto">
                        <label for="show-from" class="col-form-label">Visa notis fr.o.m.</label>
                        <div class="input-group">
                            <button class="btn btn-sm btn-outline-secondary" (click)="dFrom.toggle()" type="button"><i class="bi bi-calendar3"></i></button>
                            <input id="show-from"
                                   class="form-control" 
                                   placeholder="Välj datum" 
                                   ngbDatepicker #dFrom="ngbDatepicker" 
                                   formControlName="showFrom" />
                        </div>
                    </div>
                    <div class="col-auto">
                        <label for="show-until" class="col-form-label">Visa notis t.o.m.</label>
                        <div class="input-group">
                            <button class="btn btn-sm btn-outline-secondary" (click)="dUntil.toggle()" type="button"><i class="bi bi-calendar3"></i></button>
                            <input id="show-until"
                                   class="form-control" 
                                   placeholder="Välj datum" 
                                   ngbDatepicker #dUntil="ngbDatepicker" 
                                   formControlName="showUntil" />
                        </div>
                    </div>
                    <div class="col-auto">
                        <label for="importance" class="col-form-label">Prioritet</label><br>
                        <div class="btn-group" role="group">
                            <input type="radio" [value]="1" class="btn-check" formControlName="importance" name="importance" id="importance1" />
                            <label class="btn btn-outline-info" for="importance1">Låg</label>

                            <input type="radio" [value]="2" class="btn-check" formControlName="importance" name="importance" id="importance2" />
                            <label class="btn btn-outline-success" for="importance2">Mellan</label>

                            <input type="radio" [value]="3" class="btn-check" formControlName="importance" name="importance" id="importance3" />
                            <label class="btn btn-outline-warning" for="importance3">Hög</label>
                        </div>
                        <p *ngIf="formImportance()?.touched && formImportance()?.invalid" class="form-text invalid-feedback">Välj prioritet</p>
                    </div>
                    <div class="col align-self-end text-end">
                        <div class="btn-group">
                            <button type="submit" class="btn btn-success"><i class="bi bi-check-circle-fill me-2"></i>Spara</button>
                            <button type="button" class="btn btn-secondary" (click)="closeAllNotiser()">Avbryt</button>
                        </div>
                    </div>
                </div>
            </form>
        </ng-template>
    </ng-container>

    <ul id="notiser" class="nav nav-pills flex-column my-4">
        <ng-container *ngFor="let notis of notiser()">
            <li *ngIf="showNotis(notis)" class="nav-item rounded-pill text-white mb-2"
                [class.bg-orange]="notis.importanceLevel == 3"
                [class.bg-green]="notis.importanceLevel == 2"
                [class.bg-info]="notis.importanceLevel == 1">
                <div class="py-3 px-4">
                    <div class="row">
                        <div class="col-auto align-self-center">
                            <i class="bi" style="font-size: 1.3rem"
                              [class.bi-lightning-fill]="notis.messageOwner == 1"
                              [class.bi-pin-fill]="notis.messageOwner == 2"></i>
                        </div>
                        <div class="col align-self-center">
                            <p *ngIf="hasRole(['admin']) && (notis.showFrom || notis.showUntil)" class="mb-2 fst-italic">
                                (Visas endast {{notis.showFrom ? "fr.o.m. " + (notis.showFrom|date:'yyyy-MM-dd') : ""}} {{notis.showUntil ? "t.o.m. " + (notis.showUntil|date:'yyyy-MM-dd') : ""}})
                            </p>
                            <h6 class="m-0">{{notis.text}}</h6>
                        </div>
                        <div class="col-auto align-self-center">
                            <div *ngIf="hasRole(['admin'])">
                                <button type="button" class="btn btn-sm btn-skyblue rounded-circle me-2" (click)="editNotis(notis)"> <i class="bi bi-pencil"></i> </button>
                                <button type="button" class="btn btn-sm btn-skyblue rounded-circle" (click)="deleteNotis(notis)"> <i class="bi bi-trash3"></i> </button>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ng-container>
    </ul>


    <!-- TODO: GDPR-status?? -->


    <!-- WEBBFORMULÄR & STATUS -->

    <div *ngIf="(userRoles().length>0);else noUserRoles" class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-md-6"><h4>Dina webbformulär</h4></div>
                <div class="col-md-6 d-none d-md-block"><h4>Status</h4></div> <!--(Göm "Status" vid för liten skärm)-->
            </div>
        </div>
        <div class="card-body p-0">
            <div class="row g-0">
                <div class="col-md-6">
                    <div class="list-group rounded-0">
                        <ng-container *ngFor="let protocol of outerProtocols">
                            <ng-container [ngTemplateOutlet]="protocolLink" 
                                          [ngTemplateOutletContext]="{ id: protocol.id, roles: protocol.roles, url: protocol.url, name: protocol.name }"></ng-container>
                        </ng-container>
                    </div>
                </div>
                <ng-template #protocolLink let-id="id" let-roles="roles" let-url="url" let-name="name">
                    <div class="row g-0">
                        <div class="col">
                            <a *ngIf="hasRole(roles)" 
                                (click)="goToProtocol(url)" 
                                class="list-group-item list-group-item-action clickable">
                                {{name}}
                            </a>
                        </div>
                        <div *ngIf="protocolHasElearning(id)" class="col-auto">
                            <a class="list-group-item list-group-item-action clickable info"
                              (click)="goToElearning(id)"
                               ngbTooltip="Visa instruktionsfilm" placement="right">
                                <i class="bi bi-play-circle"></i>
                            </a>
                        </div>
                    </div>
                </ng-template>
                <div class="col-md-6 d-none d-md-block">
                    <ul class="list-group rounded-0">
                        <ng-container *ngFor="let protocol of outerProtocols">
                            <ng-container *ngIf="hasRole(protocol.roles)">
                                <ng-container *ngIf="(protocol.hasStatus);else noStatus"
                                    [ngSwitch]="protocol.id">
                                    <li *ngSwitchCase="'avlidna2020'" class="list-group-item"
                                        [class.bg-orange]="avlidna2020UnmatchedCount() > 0"
                                        [class.bg-green]="avlidna2020UnmatchedCount() == 0">
                                        {{ avlidna2020UnmatchedCount() > 0 ? 
                                            "Du har " + avlidna2020UnmatchedCount() + " omatchade kompletta protokoll." : 
                                            "Du har inga omatchade kompletta protokoll." }}
                                    </li>
                                    <li *ngSwitchCase="'avlidna2024'" class="list-group-item"
                                        [class.bg-orange]="avlidna2024UnmatchedCount() > 0"
                                        [class.bg-green]="avlidna2024UnmatchedCount() == 0">
                                        {{ avlidna2024UnmatchedCount() > 0 ? 
                                            "Du har " + avlidna2024UnmatchedCount() + " omatchade kompletta protokoll." : 
                                            "Du har inga omatchade kompletta protokoll." }}
                                    </li>
                                    <li *ngSwitchCase="'q1'" class="list-group-item"
                                        [class.bg-orange]="q1ProtocolIsValid() == false"
                                        [class.bg-green]="q1ProtocolIsValid() == true">
                                        {{ q1ProtocolIsValid() ?
                                            "Årets protokoll är inlämnat." :
                                            "Årets protokoll är ej inlämnat." }}
                                    </li>
                                    <li *ngSwitchCase="'mtal'" class="list-group-item"
                                        [class.bg-orange]="pmInvalidCount() > 0"
                                        [class.bg-green]="pmInvalidCount() == 0">
                                        {{ pmInvalidCount() > 0 ?
                                            "Du har " + pmInvalidCount() + " inaktuella PM." :
                                            "Alla PM är aktuella." }}
                                    </li>
                                </ng-container>
                                <ng-template #noStatus>
                                    <li class="list-group-item"><a class="invisible">Status</a></li>
                                </ng-template>    
                            </ng-container>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <ng-template #noUserRoles>
        <div class="text-center">
            <h6 class="display-6 my-4">Du är inte registrerad för något webbformulär.</h6>
            <a class="btn btn-lg btn-success" href="https://www.icuregswe.org/data--resultat/ansok-om-behorighet/" target="_blank">Klicka här för att ansöka om behörighet.</a>
        </div>
    </ng-template>
</div>

<ng-container *ngIf="userRoles().length>0">

    <!-- RIKTLINJE FÖR SVENSK INTENSIVVÅRD -->

    <div class="bg-skyblue-dark py-3 my-3 mt-lg-4">
        <h4 class="text-center mb-0">Översikt: Riktlinje för svensk intensivvård</h4>
    </div>
    <div class="container">
        <div *ngIf="q1Protocol() == null" class="text-center">
            <h5 class="fst-italic mb-3">Vänligen lämna in protokoll för nuvarande år för att se översikt.</h5>
            <a *ngIf="hasRole(getProtocol('q1')?.roles ?? [])" class="btn btn-primary" (click)="goToProtocol(undefined, 'q1')">Gå till protokoll</a>
        </div>
        <div *ngIf="q1Protocol() != null" class="row">
            <div id="iva-kategori" class="col-md-4 col-lg-3 col-xl-2">
                <div class="card">
                    <div class="card-header">
                        <h5>IVA-kategori</h5>
                    </div>
                    <div class="card-body text-center">
                        <h2 class="my-2 fw-bold">
                            {{q1Protocol().depCategory == 1 ? 'I' : q1Protocol().depCategory == 2 ? 'II' : q1Protocol().depCategory == 3 ? 'III' : ''}}
                        </h2>
                    </div>
                </div>
            </div>
            <div id="isolering-intensivvardsplatser" class="col-md-8 col-lg-6">
                <div class="card">
                    <div class="card-header">
                        <h5>Isolerings- och intensivvårdsplatser</h5>
                    </div>
                    <div class="card-body">
                        <h6>Beläggningsbara intensivvårdsplatser: <b class="ms-2">{{q1Protocol().icCapacity}}</b> </h6>
                        <h6>Isoleringsrum: <b class="ms-2" >{{q1Protocol().isolationCapacity}}</b> </h6>
                    </div>
                </div>
            </div>
            <div id="bemanning" class="col-md-6 col-lg-3 col-xl-4">
                <div class="card">
                    <div class="card-header">
                        <h5>Bemanning</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-3 col-6 text-end pe-0"><h6>USK: </h6></div>
                            <div class="col-lg-3 col-6"><h6><b>{{q1Protocol().averageStaffCountUSK}}</b></h6></div>
                            <div class="col-lg-3 col-6 text-end pe-0"><h6>SSK: </h6></div>
                            <div class="col-lg-3 col-6"><h6><b>{{q1Protocol().averageStaffCountSUSK}}</b></h6></div>
                            <div class="col-lg-3 col-6 text-end pe-0"><h6>ST: </h6></div>
                            <div class="col-lg-3 col-6"><h6><b>{{q1Protocol().averageStaffCountST}}</b></h6></div>
                            <div class="col-lg-3 col-6 text-end pe-0"><h6>Spec: </h6></div>
                            <div class="col-lg-3 col-6"><h6><b>{{q1Protocol().averageStaffCountSpec}}</b></h6></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- STRUKTURELLA INDIKATORER, ORGANDONATION -->

    <div class="bg-skyblue-dark py-3 my-3 mt-lg-4">
        <h4 class="text-center mb-0">Översikt: Strukturella indikatorer, organdonation</h4>
    </div>
    <div class="container">
        <div class="row">
            <!-- ALLMÄNT -->
            <div class="col-lg-4 col-md-6">
                <div *ngIf="mtalGeneral() != null" class="card general-card">
                    <div class="card-header"> <h5>Allmänt</h5> </div>
                    <div class="card-body">
                        <h5 class="mb-4">IVA-kategori</h5>
                        <div class="text-center mb-4">
                            <h1 *ngIf="mtalGeneral().avdCategory > 0">{{ getCategoryText((mtalGeneral()).avdCategory) }}</h1>
                            <h4 *ngIf="mtalGeneral().avdCategory == null" class="fst-italic">Ej angivet</h4>
                        </div>
                        <h5 class="mb-4">Donationsprocessen</h5>
                        <div class="text-center">
                            <h6>Utbildning för An/Op/IVA-personal</h6>
                            <p *ngIf="mtalGeneral().educationAnOpIVAPersonal == null" class="fst-italic">Ej angivet</p>
                            <h6 *ngIf="mtalGeneral().educationAnOpIVAPersonal != null" 
                                class="general-edu-badge"
                                [class.bg-success]="mtalGeneral().educationAnOpIVAPersonal == true"
                                [class.bg-danger]="mtalGeneral().educationAnOpIVAPersonal == false">
                                {{ mtalGeneral().educationAnOpIVAPersonal == true ? "Finns" : "Finns ej" }}
                            </h6>
                            <h6>Utbildning för övrig personal</h6>
                            <p *ngIf="mtalGeneral().educationOtherPersonal == null" class="fst-italic">Ej angivet</p>
                            <h6 *ngIf="mtalGeneral().educationOtherPersonal != null"
                                class="general-edu-badge"
                                [class.bg-success]="mtalGeneral().educationOtherPersonal == true"
                                [class.bg-danger]="mtalGeneral().educationOtherPersonal == false">
                                {{ mtalGeneral().educationOtherPersonal == true ? "Finns" : "Finns ej" }}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>

            <!-- BEMANNING -->
            <div class="col-lg-8 col-md-6">
                <div *ngIf="mtalCommissions().length > 0 && mtalCommissionHours().length > 0"
                    class="card commission-card">
                    <div class="card-header"> <h5>Bemanning just nu</h5> </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <h6>rDAL - {{mtalCommissionHours()[3]}} h/vecka</h6>
                                    <ng-container *ngFor="let c of mtalCommissions()[3]">
                                        <ng-container [ngTemplateOutlet]="commissionPerson" [ngTemplateOutletContext]="{c:c}"></ng-container>
                                    </ng-container>
                                </div>
                                <h6>DAL - {{mtalCommissionHours()[1]}} h/vecka</h6>
                                <ng-container *ngFor="let c of mtalCommissions()[1]">
                                    <ng-container [ngTemplateOutlet]="commissionPerson" [ngTemplateOutletContext]="{c:c}"></ng-container>
                                </ng-container>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <h6>rDAS - {{mtalCommissionHours()[4]}} h/vecka</h6>
                                    <ng-container *ngFor="let c of mtalCommissions()[4]">
                                        <ng-container [ngTemplateOutlet]="commissionPerson" [ngTemplateOutletContext]="{c:c}"></ng-container>
                                    </ng-container>
                                </div>
                                <h6>DAS - {{mtalCommissionHours()[2]}} h/vecka</h6>
                                <ng-container *ngFor="let c of mtalCommissions()[2]">
                                    <ng-container [ngTemplateOutlet]="commissionPerson" [ngTemplateOutletContext]="{c:c}"></ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <ng-template #commissionPerson let-c="c">
                            <p class="commission-clickable" (click)="openPersonModal(c)">
                                <span class="pe-2"><i class="bi bi-person-circle me-2"></i>{{c.mtalOrganPerson.firstname}} {{c.mtalOrganPerson.lastname}}</span>
                                <span class="only-for-printout">{{c.mtalOrganPerson.email}}</span>
                                <small>({{c.avdNamn}})</small>
                            </p>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

        <!-- PM -->
        <div *ngIf="pms().length > 0" class="card pm-card">
            <div class="card-header"> <h5>PM</h5> </div>
            <div class="card-body">
                <table class="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th>PM</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let pm of pms()">
                            <td>{{pm.infMtalOrganPMTypeId}}. {{getPMTypeName(pm.infMtalOrganPMTypeId)}}</td>
                            <td>
                                <span class="badge" [class.bg-success]="pm.isValid" [class.bg-danger]="!pm.isValid">
                                    {{pm.isValid ? "Aktuellt" : "Ej Aktuellt"}}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- UTBILDNINGAR -->
        <div class="card edu-card">
            <div class="card-header"> <h5>Utbildningar</h5> </div>
            <div class="card-body">
                <h5>Kommande utbildningar</h5>
                <p *ngIf="mtalFutureEvents().length == 0" class="fst-italic">
                    Just nu finns det inga kommande utbildningar.
                </p>
                <table *ngIf="mtalFutureEvents().length > 0" class="table table-sm table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Utbildning</th>
                            <th>Datum</th>
                            <th>Område</th>
                            <th>Plats</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let event of mtalFutureEvents()">
                            <ng-container *ngIf="event.mtalOrganEducation != null">
                                <td>{{event.mtalOrganEducation.name}}</td>
                                <td class="text-nowrap">{{event.date|date:'yyyy-MM-dd'}}</td>
                                <td>
                                    <ng-container [ngSwitch]="event.mtalOrganEducation.infMtalOrganRegionTypeId">
                                        <span *ngSwitchCase="1">Lokal</span>
                                        <span *ngSwitchCase="2">Regional</span>
                                        <span *ngSwitchCase="3">Nationell</span>
                                    </ng-container>
                                </td>
                                <td>{{event.place}}</td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>

                <h5 class="mt-4">Genomförda utbildningar under {{thisYear}}</h5>
                <p *ngIf="mtalEdus().length == 0" class="fst-italic">I år har det ännu inte genomförts några utbildningar.</p>
                <ng-container *ngIf="mtalEdus().length > 0">
                    <div class="mb-3">
                        <h6>Lokalt</h6>
                        <p *ngIf="mtalLocalEdus().length == 0" class="fst-italic">I år har det ännu inte genomförts några lokala utbildningar.</p>
                        <ng-container *ngIf="mtalLocalEdus().length > 0" 
                                    [ngTemplateOutlet]="eduCards" [ngTemplateOutletContext]="{edus: mtalLocalEdus()}"></ng-container>
                    </div>
                    <div class="mb-3">
                        <h6>Regionalt</h6>
                        <p *ngIf="mtalRegEdus().length == 0" class="fst-italic">I år har det ännu inte genomförts några regionala utbildningar.</p>
                        <ng-container *ngIf="mtalRegEdus().length > 0" 
                                        [ngTemplateOutlet]="eduCards" [ngTemplateOutletContext]="{edus: mtalRegEdus()}"></ng-container>
                    </div>
                    <div class="mb-3">
                        <h6>Nationellt</h6>
                        <p *ngIf="mtalNatEdus().length == 0" class="fst-italic">I år har det ännu inte genomförts några nationella utbildningar.</p>
                        <ng-container *ngIf="mtalNatEdus().length > 0"
                                        [ngTemplateOutlet]="eduCards" [ngTemplateOutletContext]="{edus: mtalNatEdus()}"></ng-container>
                    </div>
                </ng-container>
                <ng-template #eduCards let-edus="edus" let-header="header">
                    <div class="row">
                        <div class="col-lg-4 col-md-6" *ngFor="let edu of edus">
                            <div class="card edu-inner-card">
                                <div class="card-header"><p class="m-0">{{edu.name}}</p></div>
                                <div class="card-body">
                                    <div class="row g-2">
                                        <div class="col-6"
                                            *ngFor="let event of edu.mtalOrganEducationEvent">
                                            <div class="card">
                                                <div class="card-body">
                                                    <i *ngIf="event.isAccomplished" class="bi bi-check-circle-fill text-success isAccomplished-icon" ngbTooltip="Genomfördes"></i>
                                                    <i *ngIf="!event.isAccomplished" class="bi bi-x-circle-fill text-danger isAccomplished-icon" ngbTooltip="Genomfördes ej"></i>
                                                    <p class="fw-semibold m-0 pe-3">{{event.place}}</p>
                                                    <small>{{event.date|date:'yyyy-MM-dd'}}</small><br>
                                                    <small *ngIf="event.isAccomplished">
                                                        Antal: {{event.drCount + event.nurseCount + event.assNurseCount + event.otherCount}}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

</ng-container>

</ng-container>

<ng-container *ngIf="loading()">
    <div class="text-center my-4 my-lg-5">
        <span class="spinner-border" aria-hidden="true"></span>
        <h3 class="d-inline-block ms-4 fst-italic" role="status">Laddar ...</h3>
    </div>
</ng-container>