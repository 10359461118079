import { Component, ChangeDetectionStrategy, AfterViewInit, signal, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AvlidnaService } from "@core/services/avlidna.service";
import { MtalService } from "@core/services/mtal.service";
import { NotificationService } from "@core/services/notification.service";
import { Q1Service } from "@core/services/q1.service";
import { RollService } from "@core/services/roll.service";
import { environment } from "@environments/environment";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalService } from "@shared/components/confirm-modal/confirm-modal.component";
import { MtalCommissionData, MtalEduData, MtalEduEventData, MtalGeneralData, MtalPMData } from "@shared/models/mtal-data";
import { ImportanceLevel, MessageOwner, NotificationData } from "@shared/models/notification-data";
import { Q1Data } from "@shared/models/q1-data";
import { ValueLabelOption } from "@shared/models/value-label-option";

@Component({
    templateUrl: "overview.component.html",
    styleUrls: ["overview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewComponent implements AfterViewInit {

    constructor(
        private rollService: RollService,
        private notisService: NotificationService,
        private avlidnaService: AvlidnaService,
        private q1Service: Q1Service,
        private mtalService: MtalService,
        private confirmModal: ConfirmModalService,
        private router: Router
    ) {
    }

    ngAfterViewInit(): void {
        this.setUserRoles();
        this.getNotiser();
        this.getProtocolStatus();
        this.getMtalData();
    }
    
    loading = signal(false);

    /** 
     * Authorization 
     **/
    isAdmin = signal(false);
    userRoles = signal<string[]>([]);
    private setUserRoles() {
        this.loading.set(true);
        this.rollService.getUserRoles().subscribe({
            next: (roles) => {
                this.userRoles.set(roles);
                this.rollService.userIsAdmin().subscribe(isAdmin => this.isAdmin.set(isAdmin));
                this.loading.set(false);
            },
            error: error => { 
                this.setServiceError(error);
                this.loading.set(false);
            }
        });
    }

    /** 
     * Notifications 
     **/
    notiser = signal<NotificationData[]>([]);
    getNotiser() {
        this.notisService.getAll().subscribe({
            next: data => this.notiser.set(data),
            error: error => this.setServiceError(error)
        });
    }
    showNotis(notis: NotificationData): boolean {
        if (!notis || !notis.text) return false;
        if (this.hasRole(["admin"])) return true;
        const today = new Date();
        if ((notis.showFrom == undefined || notis.showFrom <= today) &&
            (notis.showUntil == undefined || notis.showUntil >= today)) { 
            return true;
        }
        return false;
    }

    defaultNotisForm = new FormGroup({
        text: new FormControl("", Validators.required),
        showFrom: new FormControl<NgbDateStruct|null>(null),
        showUntil: new FormControl<NgbDateStruct|null>(null),
        importance: new FormControl<ImportanceLevel|null>(null, Validators.required)
    });
    notisForm = signal(this.defaultNotisForm);
    formText() { return this.notisForm().get('text'); }
    formImportance() { return this.notisForm().get('importance'); }

    openSIRnotis = signal(false);
    openOTIMOnotis = signal(false);
    toggleOpenSIRnotis() {
        this.openSIRnotis.set(!this.openSIRnotis())
        this.openOTIMOnotis.set(false);
    }
    toggleOpenOTIMOnotis() {
        this.openOTIMOnotis.set(!this.openOTIMOnotis());
        this.openSIRnotis.set(false);
    }
    closeAllNotiser() {
        this.openSIRnotis.set(false);
        this.openOTIMOnotis.set(false);
        this.clearNotisForm();
    }
    clearNotisForm() {
        const form = this.notisForm();
        form.patchValue({ text: "", showFrom: null, showUntil: null, importance: null });
        form.markAsPristine();
        this.notisForm.set(form);
    }

    private editingNotis?: NotificationData;
    editNotis(notis: NotificationData) {
        this.editingNotis = notis;
        const form = this.notisForm();
        notis.showFromDateStr = notis.showFrom?.toNgbDateStruct();
        notis.showUntilDateStr = notis.showUntil?.toNgbDateStruct();
        form.patchValue({
            text: notis.text,
            showFrom: notis.showFromDateStr,
            showUntil: notis.showUntilDateStr,
            importance: notis.importanceLevel
        });
        this.notisForm.set(form);
        if (notis.messageOwner == MessageOwner.Otimo) this.openOTIMOnotis.set(true);
        else this.openSIRnotis.set(true);
    }

    deleteNotis(notis: NotificationData) {
        const modalTitle = `<i class="bi bi-info-circle me-3"></i>Radera notis`;
        const modalBody = `
            <p>Vill du radera notisen:</p>
            <h6>"${notis.text}"</h6>
        `;
        this.confirmModal.show(modalTitle, modalBody, "Radera", true, "md").subscribe((reason) => {
            // Confirmed == Radera
            if (reason == "Confirmed") {
                return this.notisService.delete(notis.id).subscribe({
                    next: () => this.getNotiser(),
                    error: error => this.setServiceError(error)
                });
            }
            return; 
        });
    }

    saveNotis(owner: MessageOwner) {
        const form = this.notisForm();
        if (form.valid) {
            let notis = new NotificationData();
            if (this.editingNotis != null) notis = this.editingNotis;
            else notis.messageOwner = owner;
            
            notis.text = form.get("text")?.value ?? "";
            notis.showFrom = this.ngbDateStructToDate(form.get("showFrom")?.value ?? undefined);
            notis.showUntil = this.ngbDateStructToDate(form.get("showUntil")?.value ?? undefined);
            notis.importanceLevel = form.get("importance")?.value ?? null;

            this.notisService.save(notis).subscribe({
                next: () => this.getNotiser(),
                error: error => this.setServiceError(error)
            })
            this.closeAllNotiser();
            this.clearNotisForm();
            this.editingNotis = undefined;
        } else {
            form.markAllAsTouched();
            this.notisForm.set(form);
        }
    }


    /**
     *  WEBBFORMULÄR
     **/

    outerProtocols = [
        { id: "ivaplatser",   roles: ["ivaPlatsreg","ivaPlatsList"], url: "/ivaplatser",          name: "Intensivvårdsplatser",               hasStatus: false },
        { id: "uttag",        roles: ["uttagUser"],                  url: "/uttag",               name: "Uttag",                              hasStatus: false },
        { id: "avlidna2020",  roles: ["avlidnaUser"],                url: "/avlidna2020",         name: "Avliden på IVA fr.o.m. 2020",        hasStatus: true },
        { id: "avlidna2024",  roles: ["avlidnaUser"],                url: "/avlidna2024",         name: "Avliden på IVA fr.o.m. 2024",        hasStatus: true },
        { id: "filer",        roles: ["filnedladdning"],             url: "/filer",               name: "Nedladdning av rapportfiler",        hasStatus: false },
        { id: "kontakt",      roles: ["kontaktUser"],                url: "/kontakt",             name: "Kontakthantering",                   hasStatus: false },
        { id: "mig",          roles: ["migUser"],                    url: "/mig",                 name: "Vårdbegäran",                        hasStatus: false },
        { id: "postiva",      roles: ["postivaUser"],                url: "/postiva",             name: "Uppföljning efter intensivvård",     hasStatus: false },
        { id: "q1",           roles: ["riktlinjeQ1"],                url: "/riktlinjeq1",         name: "Riktlinje för svensk intensivvård",  hasStatus: true },
        { id: "mtal",         roles: ["mtalOrgan"],                  url: "/strukturind",         name: "Strukturella indikatorer, organdonation", hasStatus: true },
        { id: "siri-inf",     roles: ["siriUser"],                   url: "/siri/list/influensa", name: "SIRI - Influensa",                   hasStatus: false },
        { id: "siri-covid",   roles: ["siriUser"],                   url: "/siri/list/covid",     name: "SIRI - Coronavirus",                 hasStatus: false },
        { id: "webval",       roles: ["fileUser"],                   url: "/validering",          name: "WebVal - IVA-fil",                   hasStatus: false }
    ];
    protocolElearning = [
        { id: "webval", elearningId: "345934920" }
    ];
    hasRole(roles: string[]): boolean {
        return roles.some(r => this.userRoles().indexOf(r) > -1);
    }
    protocolHasElearning(protocolId: string): boolean {
        return this.protocolElearning.find(e => e.id == protocolId) != null;
    }
    getProtocol(id: string) {
        return this.outerProtocols.find(p => p.id == id);
    }
    goToProtocol(url?: string, id?: string) {
        let fullUrl = environment.baseUri;
        if (url) fullUrl += url;
        if (id) fullUrl += this.getProtocol(id)?.url;
        window.open(fullUrl, "_blank");
    }
    goToElearning(protocolId: string) {
        const videoId = this.protocolElearning.find(e => e.id == protocolId)?.elearningId;
        this.router.navigate(["elearning", videoId]);
    }

    /* Status */
    avlidna2020UnmatchedCount = signal(0);
    avlidna2024UnmatchedCount = signal(0);
    q1Protocol = signal<Q1Data>({});
    q1ProtocolIsValid = signal(true);
    pmInvalidCount = signal(0);
    pms = signal<MtalPMData[]>([]);

    private getProtocolStatus() {
        /* TODO: Ivaplatser - Visa status om de rapporterat idag eller ej */

        this.avlidnaService.getUnmatchedCount(2020).subscribe({
            next: data => this.avlidna2020UnmatchedCount.set(data),
            error: error => this.setServiceError(error)
        });
        this.avlidnaService.getUnmatchedCount(2024).subscribe({
            next: data => this.avlidna2024UnmatchedCount.set(data),
            error: error => this.setServiceError(error)
        });

        this.q1Service.getLatest().subscribe({
            next: data => {
                this.q1Protocol.set(data);
                const hasCompleteQ1 = data != null;
                this.q1ProtocolIsValid.set(hasCompleteQ1);
            },
            error: error => this.setServiceError(error)
        });

        this.mtalService.getAllPM().subscribe({
            next: (data: MtalPMData[]) => {
                const pmsWithEndDates = this.mtalService.getPMTypeWithEndDates(data);
                const pms = this.mtalService.getTypedList(pmsWithEndDates);
                const invalidPMs = pms.filter(pm => pm.isValid == false).length;
                this.pmInvalidCount.set(invalidPMs);
                this.pms.set(pms);
            },
            error: error => this.setServiceError(error)
        });
    }

    /** 
     * Mätetal 
     **/
    private defaultMtalGeneral: MtalGeneralData = { avdCategory: 0, educationAnOpIVAPersonal: true, educationOtherPersonal: true };
    mtalGeneral = signal<MtalGeneralData>(this.defaultMtalGeneral);
    mtalCommissions = signal<MtalCommissionData[][]>([]);
    mtalCommissionHours = signal<number[]>([]);
    mtalPMTypes = signal<ValueLabelOption[]>([]);

    mtalFutureEvents = signal<MtalEduEventData[]>([]);
    mtalEdus = signal<MtalEduData[]>([]);
    mtalLocalEdus = signal<MtalEduData[]>([]);
    mtalRegEdus = signal<MtalEduData[]>([]);
    mtalNatEdus = signal<MtalEduData[]>([]);
    mtalNationalEduNames = signal<ValueLabelOption[]>([]);

    private getMtalData() {
        this.loadMtalGeneral();
        this.loadMtalCommissions();
        this.loadMtalPMs();
        this.loadMtalEducations();
    }

    private loadMtalGeneral() {
        this.mtalService.getGeneral().subscribe({
            next: (data: MtalGeneralData) => this.mtalGeneral.set(data),
            error: error => this.setServiceError(error)
        })
    }

    private loadMtalCommissions() {
        this.mtalService.getCommissions().subscribe({
            next: (data: MtalCommissionData[]) => { 
                const commissions: MtalCommissionData[][] = [[], [], [], [], []];
                const hours = [0, 0, 0, 0, 0];
                const today = new Date();
                data.forEach(c => {
                    if (c.startDate <= today && (c.endDate == null || c.endDate > today) && c.infMtalOrganProfessionTypeId > 0) {
                        commissions[c.infMtalOrganProfessionTypeId].push(c);
                        hours[c.infMtalOrganProfessionTypeId] += c.weeklyHours;
                    }
                });
                this.mtalCommissions.set(commissions);
                this.mtalCommissionHours.set(hours);
            },
            error: error => this.setServiceError(error)
        });
    }

    private loadMtalPMs() {
        this.mtalService.getPMTypeNames().subscribe({
            next: (data: ValueLabelOption[]) => this.mtalPMTypes.set(data),
            error: error => this.setServiceError(error)
        });
    }

    thisYear = 2023;
    private loadMtalEducations() {
        this.mtalService.getEdus().subscribe({
            next: (data: MtalEduData[]) => {
                const futureEvents: MtalEduEventData[] = [];
                const today = new Date();
                this.thisYear = today.getFullYear();
                for (let e = data.length-1; e >= 0; e--) {
                    if (data[e].infMtalOrganRegionTypeId == 3) data[e].name = this.getNationalEduName(data[e].infMtalOrganNationalEducationTypeId ?? 0);
                    const edu = data[e];
                    let hasEventsThisYear = false;
                    for (let ev = data[e].mtalOrganEducationEvent.length-1; ev >= 0; ev--) {
                        //Lägg till framtida utbildningstillfällen
                        const event = edu.mtalOrganEducationEvent[ev];
                        if (today < event.date) { 
                            //Framtida event
                            event.mtalOrganEducation = edu;
                            data[e].mtalOrganEducationEvent.splice(ev, 1); //Ta bort framtida event från "genomförda" listan
                            futureEvents.push(event);
                        }
                        //Ta bort gamla tillfällen från föregående år
                        if (this.thisYear == event.date.getFullYear()) {
                            hasEventsThisYear = true;
                        }
                        else {
                            data[e].mtalOrganEducationEvent.splice(ev, 1);
                        } 
                    }
                    if (!hasEventsThisYear || data[e].mtalOrganEducationEvent.length == 0)
                        data.splice(e, 1);
                }
                this.mtalEdus.set(data.slice());
                this.mtalLocalEdus.set(data.filter(edu => edu.infMtalOrganRegionTypeId == 1));
                this.mtalRegEdus.set(data.filter(edu => edu.infMtalOrganRegionTypeId == 2));
                this.mtalNatEdus.set(data.filter(edu => edu.infMtalOrganRegionTypeId == 3));
                this.mtalFutureEvents.set(futureEvents.sort(this.sort("date")));
            },
            error: error => this.setServiceError(error)
        });
        this.mtalService.getNationalEduNames().subscribe({
            next: (data: ValueLabelOption[]) => this.mtalNationalEduNames.set(data),
            error: error => this.setServiceError(error)
        });
    }

    serviceError = signal<string[]>([]);
    private setServiceError(error) {
        console.error(error);
        const errors = this.serviceError();
        errors.push(error);
        this.serviceError.set(errors);
    }

    /** Modals **/
    public openPersonModal(commission: MtalCommissionData) {
        const modalTitle = `<i class="bi bi-person-circle me-3"></i>${commission.mtalOrganPerson?.firstname} ${commission.mtalOrganPerson?.lastname}`;
        const modalBody = `
            <dl class="row">
                <dt class="col-md-4 text-md-end">IVA</dt>
                <dd class="col-md-8">${commission.avdNamn}</dd>
                <dt class="col-md-4 text-md-end">E-post</dt>
                <dd class="col-md-8"><a href="mailto:${commission.mtalOrganPerson?.email}">${commission.mtalOrganPerson?.email}</a></dd>
            </dl>
        `;
        this.confirmModal.show(modalTitle, modalBody, "OK", false, "md").subscribe(() => {});
    }

    /** Helper Funcs **/
    private sort(property: string, sortAscending = true) {
        let sortOrder = -1;
        if (sortAscending)
            sortOrder = 1;

        return function (a: object, b: object) {
            const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
            return result * sortOrder;
        };
    }

    public getCategoryText(category: number): string {
        return this.mtalService.getCategoryText(category);
    }

    public getPMTypeName(pmType: number): string {
        const pmTypes = this.mtalPMTypes();
        const pm = pmTypes?.find(pm => pm.value == pmType);
        return pm?.label ?? "Okänt PM";
    }

    public getNationalEduName(nationalEduId: number): string {
        const nationalEduNames = this.mtalNationalEduNames();
        const edu = nationalEduNames?.find(edu => edu.value == nationalEduId);
        return edu?.label ?? "Okänd utbildning";
    }

    private ngbDateStructToDate(date?: NgbDateStruct): Date|undefined {
        if (date) {
            return new Date(date.year, date.month-1, date.day);
        }
        return undefined;
    }

}
