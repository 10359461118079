import { Injectable, signal } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, map, of } from "rxjs";
import { environment } from "@environments/environment";
import { RoleNameData } from "@shared/models/user-data";

@Injectable({
    providedIn: "root"
})
export class RollService {

    constructor( private http: HttpClient ) { }

    public getAll(): Observable<RoleNameData[]> {
        const url = environment.apiBaseUri + "/role/getAll";
        return this.http.get<RoleNameData[]>(url);
    }

    private _userRoles = signal<string[]>([]);
    private _isAdmin = signal<boolean|null>(null);

    public getUserRoles(): Observable<string[]> {
        if (this._userRoles().length > 0) {
            return of(this._userRoles());
        } 
        else {
            const url = environment.apiBaseUri + "/role/getUserRoles";
            return this.http.get<string[]>(url).pipe(map(roles => {
                this._userRoles.set(roles); //Spara roller i service innan de returneras
                return roles;
            }));
        }
    }

    public userIsAdmin(): Observable<boolean> {
        const savedIsAdmin = this._isAdmin();
        if (savedIsAdmin != null) {
            return of(savedIsAdmin);
        } 
        else {
            return this.getUserRoles().pipe(map(roles => {
                const isAdmin = (roles.indexOf("admin") > -1);
                this._isAdmin.set(isAdmin); //Spara "isAdmin" i service innan det returneras
                return isAdmin;
            }));
        }
    }

}
